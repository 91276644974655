import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { CardContext } from './CardContext';
import { CardHeader } from './CardHeader';

const CardStyle = styled('div', {
  borderRadius: 4,
  boxSizing: 'border-box',
  border: '1px solid',
  borderColor: '$gray4',
  boxShadow: `
    rgb(0 0 0 / 5%) 0px 1px 3px,
    rgb(0 0 0 / 5%) 0px 10px 15px -5px,
    rgb(0 0 0 / 4%) 0px 7px 7px -5px
  `,

  '& + &': {
    marginTop: '$lg',
  },

  variants: {
    isNested: {
      true: { backgroundColor: '$gray1' },
      false: { backgroundColor: '$white' },
    },
    fullHeight: {
      true: {
        height: '100%',
        position: 'relative',
      },
    },
  },
});

export const Card = ({
  size,
  children,
  collapse,
  header,
  defaultIsOpen,
  ...props
}) => {
  const abyssProps = useAbyssProps(props);
  const context = useContext(CardContext);

  const { collapseProps, buttonProps, isOpen } = useCollapse({
    defaultIsOpen,
  });

  const cardChildren = collapse ? (
    <div {...collapseProps}>{children}</div>
  ) : (
    children
  );

  return (
    <CardContext.Provider
      value={{
        size,
        collapse,
        buttonProps,
        isOpen,
        isNested: !!context,
      }}
    >
      <CardStyle
        {...props}
        {...abyssProps('card-root')}
        size={size}
        collapse={collapse}
        isNested={!!context}
      >
        {header && <CardHeader {...props}>{header}</CardHeader>}
        {cardChildren}
      </CardStyle>
    </CardContext.Provider>
  );
};

Card.displayName = '@abyss/web/ui/Card';

Card.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  collapse: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Card.defaultProps = {
  children: null,
  fullHeight: false,
  size: 'medium',
  collapse: false,
  defaultIsOpen: true,
  header: null,
};
