import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'POST_FEEDBACK_SUBMISSION';

export const resetFeedback = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const useFeedbackSubmission = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const eapm = window.elasticApm;
    if (eapm) {
      window.elasticApm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: true,
      });
    }
    const { variables } = args;
    const body = variables.values;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('APIM_STARGATE_URL')}${config('FEEDBACK_URL')}`, {
        credentials: 'include',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then(res => {
          event('API_TRANSACTION', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('FEEDBACK_URL'),
              userInfo: args?.userInfo,
              method: 'POST',
              code: res.status,
              operationName: ACTION_TYPE,
              message: res.statusText,
            },
          });
          return res;
        })
        .then(res => {
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('FEEDBACK_URL'),
              userInfo: args?.userInfo,
              method: 'POST',
              operationName: ACTION_TYPE,
              // code: res.status,
              // message: res.statusText,
            },
          });
        });
    };
  });

  return [result, action];
};
