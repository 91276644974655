import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';
import { parseByTag, parseTagNames } from './parsers';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_DOCUMENTATION';

export const useDocumentation = options => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(args => {
    const eapm = window.elasticApm;
    if (eapm) {
      window.elasticApm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: true,
      });
    }
    const { service } = args;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('DOCUMENTATION_API_URL')}${service}.json`)
        .then(res => {
          event('API_TRANSACTION', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('DOCUMENTATION_API_URL'),
              userInfo: args?.userInfo,
              method: 'GET',
              code: res.status,
              operationName: ACTION_TYPE,
              message: res.statusText,
            },
          });
          return res.json();
        })
        .then(res => {
          const s = res;
          const pathsByTag = parseByTag(s, service);
          const tagNames = parseTagNames(pathsByTag);
          const data = {
            ...s,
            pathsByTag,
            tagNames,
            service,
          };

          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: data,
          });
          if (options && options.onCompleted) {
            options.onCompleted(data);
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          if (options && options.onError) {
            options.onError(error);
          }
        });

      event('API_TRANSACTION_ERROR', {
        fields: {
          appVersion: process.env.PACKAGE_VERSION,
          url: window.location.href,
          endpoint: config('DOCUMENTATION_API_URL'),
          userInfo: args?.userInfo,
          method: 'GET',
          operationName: ACTION_TYPE,
          // code: res.status,
          // message: res.statusText,
        },
      });
    };
  });

  return [result, action];
};
