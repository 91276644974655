import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';
import { adobeAnalytics } from '@abyss/web/tools/event/listeners/adobeAnalytics';
import { elasticApmAnalytics } from './elasticApm';
import { adobeTags } from './tags/adobe';
import { elasticApmTags } from './tags/elasticApm';

const maskAdobeClickTracking = () => {
  event.waitFor(
    () => {
      return window.s && window.s.ActivityMap;
    },
    () => {
      window.s.ActivityMap.link = () => null;
    }
  );
};

export const ADOBE_ANALYTICS_ID = 'adobe-analytics-apimarketplace';

export const registerAnalytics = data => {
  maskAdobeClickTracking();

  const adobeData = adobeAnalytics({
    id: 'adobe-analytics-apimarketplace',
    enabled: config('ANALYTICS_TRACKING'),
    logging: config('ANALYTICS_LOGGING'),
    events: adobeTags,
    metadata: data,
  });

  const elasticApmData = elasticApmAnalytics({
    id: 'elastic-apm',
    enabled: config('ANALYTICS_TRACKING'),
    logging: config('ANALYTICS_LOGGING'),
    events: elasticApmTags,
    metadata: data,
  });

  event.listener(elasticApmData);

  event.listener(adobeData);
};
