import React, { useEffect, useState } from 'react';
import { Route } from '@abyss/ui/router/Route';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import PropTypes from 'prop-types';
import { config } from '@abyss/ui/tools/config';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { storage } from '@abyss/web/tools/storage';

export const ProtectedRoute = ({
  component: Component,
  setPath,
  breadcrumbsHandler,
  ...rest
}) => {
  const [authScriptLoaded, setAuthScriptLoaded] = useState(false);
  const [userInfo, getUserInfo] = useUserInfo();

  useEffect(() => {
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      script.onload = () => {
        getUserInfo();
        setAuthScriptLoaded(true);
      };
      document.head.appendChild(script);
    } else {
      setAuthScriptLoaded(true);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (setPath) {
          setPath(props);
        }
        if (breadcrumbsHandler) {
          breadcrumbsHandler(props);
        }
        return authScriptLoaded && userInfo?.data?.uuid ? (
          <Component {...props} />
        ) : (
          <LoadingOverlay
            loadingTitle="Please Wait"
            loadingMessage={
              storage.local.get('IS_SIGNED_OUT') ? 'Signing out' : 'Signing in'
            }
            ariaLoadingLabel={
              storage.local.get('IS_SIGNED_OUT') ? 'Signing out' : 'Signing in'
            }
            isLoading
            width="60%"
          />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  setPath: PropTypes.func.isRequired,
  breadcrumbsHandler: PropTypes.func,
};

ProtectedRoute.defaultProps = {
  breadcrumbsHandler: null,
};
